export default defineI18nConfig(() => ({
    legacy: false,
    messages: {
        'en-US': {
            app: {
                nameShort: 'RPHQ',
                nameLong: 'RedpointHQ'
            },
            summary: {
                welcome: 'Welcome {name}',
                storeCredit: 'Store Credit',
                accountBalance: 'Account Balance',
                makeAccountPayment: 'Make Account Payment',
                accountAlerts: 'Account Alerts',
                accountAlertsEmpty: 'Everything looks good!',
                recentOrders: 'Recent Orders',
                andOtherItems: 'and 1 other item | and {n} other items',
                activeBenefits: 'Active Benefits'
            },
            benefit: 'Benefit | Benefits',
            benefits: {
                name: 'Name',
                quantity: 'Quantity',
                unlimited: 'Unlimited'
            },
            membership: 'Membership | Memberships',
            contracts: {
                status: 'Status',
                draft: 'Draft',
                confirmed: 'Confirmed',
                active: 'Active',
                frozen: 'Frozen',
                terminated: 'Terminated',
                concluded: 'Concluded'
            },
            booking: 'Booking | Bookings',
            facility: 'Facility | Facilities',
            homeFacility: 'Home Facility'
        }
    },
    fallbackLocale: 'en-US'
}))
